// Here you can add other styles

.sidebar {
  // When colapsed, the sidebar-nav is too wide. The extra width comes from 
  // the padding on the sidebar: 1rem. 1rem is about 16px so we reduced the
  // size of the list items by that amount.
  .sidebar-nav li {
    width: $sidebar-width - 16px !important;
  }
}

input#licensePlate {
  text-transform: uppercase;
}

.scrollable {
  overflow: scroll;
  border: solid #e0e0e0 1px;
  padding: 2em 0;
}

select[name="tableList_length"] {
  display: inline
}
#tableList_filter input {
  display: inline;
  margin-left: 1em;
}